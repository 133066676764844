// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-10-second-demo-js": () => import("./../../../src/pages/10-second-demo.js" /* webpackChunkName: "component---src-pages-10-second-demo-js" */),
  "component---src-pages-10-ways-to-save-admin-home-to-school-services-js": () => import("./../../../src/pages/10-ways-to-save-admin-home-to-school-services.js" /* webpackChunkName: "component---src-pages-10-ways-to-save-admin-home-to-school-services-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-7-ways-coronavirus-could-affect-home-to-school-transport-js": () => import("./../../../src/pages/7-ways-coronavirus-could-affect-home-to-school-transport.js" /* webpackChunkName: "component---src-pages-7-ways-coronavirus-could-affect-home-to-school-transport-js" */),
  "component---src-pages-7-ways-to-keep-parents-happy-on-your-school-services-js": () => import("./../../../src/pages/7-ways-to-keep-parents-happy-on-your-school-services.js" /* webpackChunkName: "component---src-pages-7-ways-to-keep-parents-happy-on-your-school-services-js" */),
  "component---src-pages-8-reasons-standing-orders-are-bad-for-business-js": () => import("./../../../src/pages/8-reasons-standing-orders-are-bad-for-business.js" /* webpackChunkName: "component---src-pages-8-reasons-standing-orders-are-bad-for-business-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-our-story-js": () => import("./../../../src/pages/about/our-story.js" /* webpackChunkName: "component---src-pages-about-our-story-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-book-demo-js": () => import("./../../../src/pages/book-demo.js" /* webpackChunkName: "component---src-pages-book-demo-js" */),
  "component---src-pages-bounce-back-offer-js": () => import("./../../../src/pages/bounce-back-offer.js" /* webpackChunkName: "component---src-pages-bounce-back-offer-js" */),
  "component---src-pages-bounce-back-offer-requested-js": () => import("./../../../src/pages/bounce-back-offer-requested.js" /* webpackChunkName: "component---src-pages-bounce-back-offer-requested-js" */),
  "component---src-pages-brickets-school-bus-tickets-on-brick-phone-js": () => import("./../../../src/pages/brickets-school-bus-tickets-on-brick-phone.js" /* webpackChunkName: "component---src-pages-brickets-school-bus-tickets-on-brick-phone-js" */),
  "component---src-pages-bus-open-data-package-info-requested-js": () => import("./../../../src/pages/bus-open-data-package-info-requested.js" /* webpackChunkName: "component---src-pages-bus-open-data-package-info-requested-js" */),
  "component---src-pages-bus-open-data-vehicle-location-package-bods-app-js": () => import("./../../../src/pages/bus-open-data-vehicle-location-package/bods-app.js" /* webpackChunkName: "component---src-pages-bus-open-data-vehicle-location-package-bods-app-js" */),
  "component---src-pages-bus-open-data-vehicle-location-package-bods-tracker-js": () => import("./../../../src/pages/bus-open-data-vehicle-location-package/bods-tracker.js" /* webpackChunkName: "component---src-pages-bus-open-data-vehicle-location-package-bods-tracker-js" */),
  "component---src-pages-bus-open-data-vehicle-location-package-index-js": () => import("./../../../src/pages/bus-open-data-vehicle-location-package/index.js" /* webpackChunkName: "component---src-pages-bus-open-data-vehicle-location-package-index-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-study-applegates-guarantee-income-safety-during-covid-by-going-cashless-js": () => import("./../../../src/pages/case-study-applegates-guarantee-income-safety-during-covid-by-going-cashless.js" /* webpackChunkName: "component---src-pages-case-study-applegates-guarantee-income-safety-during-covid-by-going-cashless-js" */),
  "component---src-pages-case-study-bullocks-months-to-minutes-js": () => import("./../../../src/pages/case-study-bullocks-months-to-minutes.js" /* webpackChunkName: "component---src-pages-case-study-bullocks-months-to-minutes-js" */),
  "component---src-pages-case-study-cambridge-buses-help-local-authority-save-time-money-js": () => import("./../../../src/pages/case-study-cambridge-buses-help-local-authority-save-time-money.js" /* webpackChunkName: "component---src-pages-case-study-cambridge-buses-help-local-authority-save-time-money-js" */),
  "component---src-pages-case-study-flagfinders-regain-control-of-admin-modernise-school-bus-service-js": () => import("./../../../src/pages/case-study-flagfinders-regain-control-of-admin-modernise-school-bus-service.js" /* webpackChunkName: "component---src-pages-case-study-flagfinders-regain-control-of-admin-modernise-school-bus-service-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-case-study-keane-travel-reduce-admin-increase-revenue-school-travel-js": () => import("./../../../src/pages/case-study-keane-travel-reduce-admin-increase-revenue-school-travel.js" /* webpackChunkName: "component---src-pages-case-study-keane-travel-reduce-admin-increase-revenue-school-travel-js" */),
  "component---src-pages-case-study-plumpton-college-save-full-time-admin-js": () => import("./../../../src/pages/case-study-plumpton-college-save-full-time-admin.js" /* webpackChunkName: "component---src-pages-case-study-plumpton-college-save-full-time-admin-js" */),
  "component---src-pages-case-study-shuttleid-credited-15-percent-growth-travel-revenue-york-college-js": () => import("./../../../src/pages/case-study-shuttleid-credited-15-percent-growth-travel-revenue-york-college.js" /* webpackChunkName: "component---src-pages-case-study-shuttleid-credited-15-percent-growth-travel-revenue-york-college-js" */),
  "component---src-pages-complete-package-js": () => import("./../../../src/pages/complete-package.js" /* webpackChunkName: "component---src-pages-complete-package-js" */),
  "component---src-pages-complete-package-pricing-requested-js": () => import("./../../../src/pages/complete-package-pricing-requested.js" /* webpackChunkName: "component---src-pages-complete-package-pricing-requested-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-submitted-js": () => import("./../../../src/pages/contact-submitted.js" /* webpackChunkName: "component---src-pages-contact-submitted-js" */),
  "component---src-pages-demo-requested-js": () => import("./../../../src/pages/demo-requested.js" /* webpackChunkName: "component---src-pages-demo-requested-js" */),
  "component---src-pages-direct-debit-arrives-shuttleid-js": () => import("./../../../src/pages/direct-debit-arrives-shuttleid.js" /* webpackChunkName: "component---src-pages-direct-debit-arrives-shuttleid-js" */),
  "component---src-pages-dte-offer-js": () => import("./../../../src/pages/dte-offer.js" /* webpackChunkName: "component---src-pages-dte-offer-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-help-using-your-ticket-js": () => import("./../../../src/pages/help-using-your-ticket.js" /* webpackChunkName: "component---src-pages-help-using-your-ticket-js" */),
  "component---src-pages-how-technology-can-save-fuel-on-your-school-bus-js": () => import("./../../../src/pages/how-technology-can-save-fuel-on-your-school-bus.js" /* webpackChunkName: "component---src-pages-how-technology-can-save-fuel-on-your-school-bus-js" */),
  "component---src-pages-how-to-keep-parents-schools-informed-during-school-bus-disruption-js": () => import("./../../../src/pages/how-to-keep-parents-schools-informed-during-school-bus-disruption.js" /* webpackChunkName: "component---src-pages-how-to-keep-parents-schools-informed-during-school-bus-disruption-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-requested-js": () => import("./../../../src/pages/info-requested.js" /* webpackChunkName: "component---src-pages-info-requested-js" */),
  "component---src-pages-is-your-coach-company-ready-for-psd-2-sca-js": () => import("./../../../src/pages/is-your-coach-company-ready-for-psd2-sca.js" /* webpackChunkName: "component---src-pages-is-your-coach-company-ready-for-psd-2-sca-js" */),
  "component---src-pages-live-addon-info-requested-js": () => import("./../../../src/pages/live-addon-info-requested.js" /* webpackChunkName: "component---src-pages-live-addon-info-requested-js" */),
  "component---src-pages-money-back-guarantee-js": () => import("./../../../src/pages/money-back-guarantee.js" /* webpackChunkName: "component---src-pages-money-back-guarantee-js" */),
  "component---src-pages-packages-js": () => import("./../../../src/pages/packages.js" /* webpackChunkName: "component---src-pages-packages-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-pricing-requested-js": () => import("./../../../src/pages/pricing-requested.js" /* webpackChunkName: "component---src-pages-pricing-requested-js" */),
  "component---src-pages-renewals-demo-js": () => import("./../../../src/pages/renewals-demo.js" /* webpackChunkName: "component---src-pages-renewals-demo-js" */),
  "component---src-pages-request-info-js": () => import("./../../../src/pages/request-info.js" /* webpackChunkName: "component---src-pages-request-info-js" */),
  "component---src-pages-school-bus-boarding-notifications-myths-busted-js": () => import("./../../../src/pages/school-bus-boarding-notifications-myths-busted.js" /* webpackChunkName: "component---src-pages-school-bus-boarding-notifications-myths-busted-js" */),
  "component---src-pages-school-bus-tracking-system-app-js": () => import("./../../../src/pages/school-bus-tracking-system-app.js" /* webpackChunkName: "component---src-pages-school-bus-tracking-system-app-js" */),
  "component---src-pages-simple-package-demo-js": () => import("./../../../src/pages/simple-package-demo.js" /* webpackChunkName: "component---src-pages-simple-package-demo-js" */),
  "component---src-pages-simple-package-js": () => import("./../../../src/pages/simple-package.js" /* webpackChunkName: "component---src-pages-simple-package-js" */),
  "component---src-pages-simple-package-pricing-requested-js": () => import("./../../../src/pages/simple-package-pricing-requested.js" /* webpackChunkName: "component---src-pages-simple-package-pricing-requested-js" */),
  "component---src-pages-staff-shuttle-service-info-requested-js": () => import("./../../../src/pages/staff-shuttle-service-info-requested.js" /* webpackChunkName: "component---src-pages-staff-shuttle-service-info-requested-js" */),
  "component---src-pages-staff-shuttle-service-js": () => import("./../../../src/pages/staff-shuttle-service.js" /* webpackChunkName: "component---src-pages-staff-shuttle-service-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-ticket-scanner-demo-js": () => import("./../../../src/pages/ticket-scanner-demo.js" /* webpackChunkName: "component---src-pages-ticket-scanner-demo-js" */),
  "component---src-pages-traditional-school-bus-passes-vs-mobile-tickets-js": () => import("./../../../src/pages/traditional-school-bus-passes-vs-mobile-tickets.js" /* webpackChunkName: "component---src-pages-traditional-school-bus-passes-vs-mobile-tickets-js" */),
  "component---src-pages-what-face-covering-facemasks-mean-for-home-to-school-transport-js": () => import("./../../../src/pages/what-face-covering-facemasks-mean-for-home-to-school-transport.js" /* webpackChunkName: "component---src-pages-what-face-covering-facemasks-mean-for-home-to-school-transport-js" */),
  "component---src-pages-who-we-help-js": () => import("./../../../src/pages/who-we-help.js" /* webpackChunkName: "component---src-pages-who-we-help-js" */),
  "component---src-pages-why-parents-dont-want-to-use-your-school-bus-js": () => import("./../../../src/pages/why-parents-dont-want-to-use-your-school-bus.js" /* webpackChunkName: "component---src-pages-why-parents-dont-want-to-use-your-school-bus-js" */)
}

